import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";

import { Skeleton } from "@mui/material";
import { saveTopicOfMonth } from "../../redux/actions/tomActions";

export const TopicOfTheMonth = ({ type }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const topicOfMonth = useSelector((state) => state.topicOfMonth);
  const [isLoading, setIsLoading] = useState(false);

  const convertHtmlToReactNode = (htmlString) => {
    const sanitizedHtml = DOMPurify.sanitize(htmlString);
    return () => <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  };

  const BodyComponent = convertHtmlToReactNode(topicOfMonth?.content);

  useEffect(() => {
    if (topicOfMonth === null) {
      setIsLoading(true);
      dispatch(saveTopicOfMonth(token)).then(() => {
        setIsLoading(false);
      });
    }
  }, [dispatch, token, topicOfMonth]);

  const renderContent = () => {
    if (type === "description") {
      return <>{topicOfMonth.description}</>;
    }

    return (
      <>
        <h2>
          {topicOfMonth.month}: {topicOfMonth.title}
        </h2>
        <BodyComponent />
      </>
    );
  };

  if (isLoading) {
    return (
      <Skeleton
        variant="text"
        sx={{
          fontSize: "1.5rem",
          width: "calc(800px - 40vw)",
          maxWidth: "100%",
          margin: "10px",
        }}
      />
    );
  }

  if (!topicOfMonth) {
    return <>Topic of the month is currently unavailable.</>;
  }

  return renderContent();
};

TopicOfTheMonth.propTypes = {
  type: PropTypes.oneOf(["description", "content"]),
};
