export default {
  apiCallsInProgress: 0,
  loading: true,
  token: {},
  user: {},
  broadcastMsgs: null,
  appointment: null,
  appointmentLinks: {},
  signOut: false,
  teamData: null,
  preReqStep: {},
  avatar: null,
  avatars: null,
  topicOfMonth: null,
};
