import React from "react";
import { NavLink } from "react-router-dom";

import Food from "../../assets/resources/Food.svg";
import HousingHomelessness from "../../assets/resources/HousingHomelessness.svg";
import LegalAssistance from "../../assets/resources/LegalAssistance.svg";
import MentalHealth from "../../assets/resources/MentalHealth.svg";
import TaxCredits from "../../assets/resources/TaxCredits.svg";
import Undocumented from "../../assets/resources/Undocumented.svg";
import VoterResources from "../../assets/resources/VoterResources.svg";

/**
 * Resources
 */
export const ResourcesData = [
  {
    id: 1,
    title: "Food",
    description:
      "Calbright cares about your well-being. Resources that can help you maintain a healthy and balanced lifestyle while you focus on your learning journey at Calbright.",
    image: Food,
    content: (
      <>
        <hr />
        <b>
          <a href="https://www.fns.usda.gov/meals4kids" target="_blank">
            After School and Summer Meal Programs for Kids
          </a>
        </b>
        <p>
          Find after school and summer meal programs for children using the USDA
          Meals for Kids Site Finder. Enter your location or address in the
          interactive map to find nearby sites, hours of operation and contact
          information. Or{" "}
          <a href="https://www.cde.ca.gov/re/mo/cameals.asp" target="_blank">
            download
          </a>{" "}
          the CA Meals for Kids Mobile Application for your iOS, Android, or
          Microsoft devices.
        </p>

        <hr />

        <b>
          <a href="https://www.cdss.ca.gov/calfresh" target="_blank">
            Cal-Fresh
          </a>
        </b>
        <p>
          CalFresh provides monthly financial support to purchase nutritious
          foods for individuals and families with low-income who meet federal
          guidelines.
        </p>
        <p>
          Apply online at:{" "}
          <a href="https://www.getcalfresh.org" target="_blank">
            https://www.getcalfresh.org
          </a>
        </p>
        <p>Cal-Fresh information: 1-877-847-3663</p>
        <p>
          Find your Cal-Fresh County Office:{" "}
          <a
            href="https://calfresh.dss.ca.gov/food/officelocator/"
            target="_blank"
          >
            https://calfresh.dss.ca.gov/food/officelocator/
          </a>
        </p>

        <hr />

        <b>
          <a href="https://www.cdss.ca.gov/food-banks" target="_blank">
            Food Banks
          </a>
        </b>
        <p>
          If you’re experiencing food insecurity, food banks are available to
          assist you. Please contact the food bank located in your county of
          residence.
        </p>
        <hr />
        <b>
          <a href="https://foodfinders.org/pantry-locator/" target="_blank">
            Food Finders
          </a>{" "}
          (Serving Southern California primarily)
        </b>
        <p>
          Food Finders is a nonprofit organization that works collaboratively
          with restaurants, grocers, corporate cafeterias and schools to address
          hunger. Food Finders partners with local food banks and shelters to
          distribute the food.
        </p>

        <hr />
        <b>
          <a href="https://www.foodpantries.org/st/california" target="_blank">
            Food Pantries
          </a>
        </b>
        <p>
          FoodPantries.org has compiled a list of California food pantries, soup
          kitchens, food banks, and other food help.
        </p>
        <hr />
        <b>
          <a href="https://www.shfb.org/get-food/" target="_blank">
            Second Harvest of Silicon Valley
          </a>{" "}
          (Serving the Bay Area)
        </b>
        <p>
          Second Harvest of Silicon Valley programs provide free nutritious
          groceries and fresh produce to individuals who can’t afford healthy
          food. Food Connection helps people find free groceries and other food
          resources in their neighborhoods. Multilingual staff provide referrals
          to local food programs and help people apply for CalFresh.
        </p>
        <hr />
        <b>
          <a
            href="https://www.hungerfreeamerica.org/en-us/usda-national-hunger-hotline"
            target="_blank"
          >
            USDA Hunger Hotline
          </a>
        </b>
        <p>
          Representatives will help you find food resources, such as meal sites,
          food banks and other social services near you. Call 1-(866) 3-HUNGRY
          or text #97779.
        </p>
        <hr />
        <b>
          <a
            href="https://www.cdph.ca.gov/Programs/CFH/DWICSN/Pages/HowCanIGetWIC.aspx"
            target="_blank"
          >
            Women, Infants & Children (WIC)
          </a>
        </b>
        <p>
          The California WIC Program is a supplemental nutrition program for
          women, infants and children who meet eligibility requirements. The
          program helps families across the state by providing nutrition
          education, breastfeeding support, healthy foods and referrals to
          health care and other community services.
        </p>
        <p>Contact information: CA WIC Customer Service</p>
        <p>Automated Tel: 1-888-942-9675</p>
        <p>In-Person Tel: 1-800-852-5770</p>
        <p>
          Email: <a href="mailto:WIC@cdph.ca.gov">WIC@cdph.ca.gov</a>
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "Housing/Homelessness",
    description:
      "A stable home is crucial for academic success. You have a safe and comfortable place to live while you concentrate on your studies.",
    image: HousingHomelessness,
    content: (
      <>
        <hr />
        <b>
          <a
            href="https://www.bcsh.ca.gov/hcfc/documents/coc_poc.pdf"
            target="_blank"
          >
            Continuum of Care
          </a>
        </b>
        <p>
          If you’re experiencing housing insecurity and facing potential
          homelessness, please contact the Continuum of Care contact for your
          county of residence.
        </p>
        <hr />
        <b>
          <a
            href="https://www.homelessshelterdirectory.org/state/california"
            target="_blank"
          >
            California Homeless Shelter Directory
          </a>
        </b>
        <p>
          If you’re currently experiencing homelessness, the link below will
          provide a list of emergency shelters, general homeless shelters and
          transitional housing opportunities in your area.
        </p>
        <hr />
        <b>
          <a
            href="https://housing.ca.gov/resources/tenant.html"
            target="_blank"
          >
            State of California Tenant Resources
          </a>
        </b>
        <p>
          View a comprehensive listing of legal aid and advocacy groups
          available by county.
        </p>
        <hr />
      </>
    ),
  },
  {
    id: 3,
    title: "Legal Assistance",
    description: "Navigating legal matters can be challenging. ",
    image: LegalAssistance,
    content: (
      <>
        <hr />
        <p>
          <b>
            <a href="https://crla.org/about-crla" target="_blank">
              California Rural Legal Assistance, Inc (CRLA)
            </a>
          </b>{" "}
          provides free civil legal services to residents with low income in
          California's rural counties.
        </p>
        <p>
          <a href="tel:+18003370690">Phone: 1-800-337-0690</a>
        </p>
        <hr />
        <p>
          <b>
            <a href="https://www.immi.org/en" target="_blank">
              Immi
            </a>
          </b>{" "}
          helps immigrants in the U.S. understand their legal options. Their
          online screening tool, legal information and referrals to nonprofit
          legal services organizations are always free.
        </p>
        <hr />
        <p>
          <b>
            <a
              href="https://www.immigrationadvocates.org/nonprofit/legaldirectory/search?state=CA"
              target="_blank"
            >
              Immigration Advocates Network (IAN)
            </a>
          </b>{" "}
          is dedicated to expanding access to immigration legal resources and
          information through collaboration and technology.
        </p>
        <hr />
        <p>
          <b>
            <a
              href="https://www.legalaidoffices.com/state/california"
              target="_blank"
            >
              LegalAid
            </a>
          </b>{" "}
          works with nonprofit organizations to provide attorney services to
          those who can’t afford it.
        </p>
        <hr />
        <p>
          <b>
            <a
              href="https://www.communitylegalsocal.org/programs-services/free-legal-workshops-and-clinics/"
              target="_blank"
            >
              Community Legal Aid SoCal
            </a>
          </b>{" "}
          provides free legal workshops for Southern California residents on
          family law, eviction defense, consumer debt defense, bankruptcy and
          expungement.
        </p>
        <hr />
      </>
    ),
  },
  {
    id: 4,
    title: "Mental Health",
    description:
      "Your mental well-being is a priority at Calbright. Access to counseling services, resources and tools designed to support your emotional and psychological health throughout your Calbright journey.",
    image: MentalHealth,
    content: (
      <>
        <hr />
        <b>
          <a href="https://www.calhope.org/" target="_blank">
            CalHOPE Connect
          </a>
        </b>
        <p>
          Offers safe, secure and culturally sensitive emotional support for all
          Californians.
        </p>
        <p>
          Chat line: <a href="tel:+18333174673">1-833-317-4673</a>
        </p>
        <hr />
        <b>
          <a
            href="https://www.mentalhealthsf.org/peer-run-warmline/"
            target="_blank"
          >
            California Warm Peer Line
          </a>
        </b>
        <p>
          Nonemergency resource for anyone in California seeking mental and
          emotional support. They provide assistance via phone and web chat on a
          nondiscriminatory basis to anyone in need. Some concerns callers share
          are challenges with interpersonal relationships, anxiety, pain,
          depression, finances and alcohol/drug use.
        </p>
        <p>
          Phone: <a href="tel:+18558457415">855-845-7415</a>
        </p>
        <p>
          Online Live Chat:{" "}
          <a
            href="https://www.mentalhealthsf.org/peer-run-warmline/"
            target="_blank"
          >
            www.mentalhealthsf.org/peer-run-warmline
          </a>
        </p>
        <p>
          Available support groups:{" "}
          <a
            href="https://www.mentalhealthsf.org/support-groups/"
            target="_blank"
          >
            www.mentalhealthsf.org/support-groups
          </a>{" "}
        </p>
        <hr />
        <b>
          <a href="https://www.crisistextline.org/text-us/" target="_blank">
            Crisis Text Line
          </a>
        </b>
        <p>24/7 support from trained volunteers for any types of crises.</p>
        <p>Text HOME to 741741.</p>
        <hr />
        <b>
          <a href="https://www.thehotline.org/" target="_blank">
            National Domestic Violence Hotline
          </a>
        </b>
        <p>
          Advocates offer free, confidential and compassionate support, crisis
          intervention information, education and referral services in over 200
          languages.
        </p>
        <p>
          Phone: <a href="tel:+18007997233">1-800-799-7233</a>{" "}
        </p>
        <p>Text: Text START to 88788</p>
        <p>
          <a href="https://www.thehotline.org/" target="_blank">
            Chat Now
          </a>
        </p>
        <hr />
        <b>
          <a href="https://suicidepreventionlifeline.org/" target="_blank">
            National Suicide Prevention Lifeline
          </a>
        </b>
        <p>
          The Lifeline provides 24/7, free and confidential support for people
          in distress, prevention and crisis resources for you or your loved
          ones, as well as best practices for professionals.
        </p>
        <p>
          Phone: <a href="tel:+1800273TALK">1-800-273-TALK</a> (available 24/7){" "}
        </p>
        <p>
          Chat online:{" "}
          <a href="https://suicidepreventionlifeline.org/chat" target="_blank">
            {" "}
            suicidepreventionlifeline.org/chat
          </a>
        </p>
        <hr />
        <b>
          <a href="https://translifeline.org/" target="_blank">
            Trans Lifeline
          </a>
        </b>
        <p>
          Phone: <a href="tel:+18775658860">1-877-565-8860</a>{" "}
        </p>
        <hr />
        <b>
          <a href="https://www.thetrevorproject.org/" target="_blank">
            Trevor Project (LGBTQ+)
          </a>
        </b>
        <p>
          Phone: <a href="tel:+18664887386">1-866-488-7386</a>{" "}
        </p>
        <hr />
        <b>
          <a
            href="https://www.veteranscrisisline.net/get-help-now/call/"
            target="_blank"
          >
            Veterans Crisis Line{" "}
          </a>
        </b>
        <p>
          Phone: Dial <a href="tel:988">988</a> then Press 1 OR Text 838255{" "}
        </p>
        <hr />
        <b>
          <a
            href="https://www.dhcs.ca.gov/individuals/Pages/MHPContactList.aspx"
            target="_blank"
          >
            Crisis Clinic Hotlines by County
          </a>
        </b>
        <p>
          This resource provides a direct link to crisis clinic hotlines by
          county.
        </p>
        <hr />
        <b>
          <NavLink to="/wellness-services">TimelyCare</NavLink>
        </b>
        <p>
          Basic medical and mental health support services are available through
          video, phone, or video chat at no cost through TimelyCare to actively
          enrolled Calbright College students.
        </p>
        <p>
          TalkNow, a service through TimelyCare, provides 24/7 on demand,
          unlimited, mental and emotional support and crisis management with a
          mental health professional. Additionally, students are able to
          schedule up to 12 counseling sessions in a year with a provider of
          their choice to work on specific issues.
        </p>
      </>
    ),
  },
  {
    id: 5,
    title: "Tax Credits",
    description: "",
    image: TaxCredits,
    content: (
      <>
        <hr />
        <b>
          <a href="https://www.caleitc4me.org/" target="_blank">
            California Earned Income Tax Credit (CITC)
          </a>
        </b>
        <p>
          Tax credits are available for workers who have low-to-moderate income.
          Learn whether you're eligible to receive a cash refund or reduce the
          amount of tax you owe.
        </p>
        <hr />
        <b>
          <a
            href="https://www.caleitc4me.org/young-child-tax-credit"
            target="_blank"
          >
            Young Child Tax Credit (YCTC)
          </a>
        </b>
        <p>
          If you qualify for CalEITC and have a child under the age of 6, you
          may also qualify for a refundable tax credit of up to $1,117 through
          the Young Child Tax Credit (YCTC). If you qualify, you may see a
          reduced tax bill or a bigger refund.
        </p>
        <hr />
      </>
    ),
  },
  {
    id: 6,
    title: "Undocumented Students Resources",
    description:
      "Calbright is committed to supporting all students. Valuable information and assistance to ensure that undocumented students have the resources to thrive academically and personally.",
    image: Undocumented,
    content: (
      <>
        <hr />
        <b>
          <a href="https://immigrantsrising.org/#" target="_blank">
            Immigrant Rising
          </a>
        </b>
        <p>
          Empowers and supports undocumented young people to achieve educational
          and career goals.
        </p>
        <p>
          This organization provides resources and support that empower
          undocumented people to get an education, pursue careers and build a
          brighter future for themselves and their communities.
        </p>
        <hr />
        <b>
          <a href="https://www.informedimmigrant.com/#" target="_blank">
            Informed Immigrant
          </a>
        </b>
        <p>
          This is a collective of immigrant serving organizations, lawyers,
          technologists and allies dedicated to helping the undocumented
          immigrant communities. Services include information on DACA renewal,
          legal services and more.
        </p>
        <hr />
      </>
    ),
  },
  {
    id: 7,
    title: "Voter Resources",
    description:
      "Your voice to make informed decisions as an active participant in our democratic process.",
    image: VoterResources,
    content: (
      <>
        <hr />
        <p>
          If you’re looking for information in this guide that you can’t find,
          please contact{" "}
          <strong>
            1-866-OUR-VOTE (<a href="tel:+18666878683">1-866-687-8683</a>)
          </strong>
          . People there can help you with questions, even on Election Day.
        </p>
        <p>
          Visit{" "}
          <a
            href="https://www.sos.ca.gov/elections/castudentsvote"
            target="_blank"
          >
            www.sos.ca.gov/elections/castudentsvote
          </a>{" "}
          for more information.
        </p>
        <hr />
        <h3>Additional Resources</h3>
        <b>
          <a href="https://www.211ca.org/" target="_blank">
            2-1-1
          </a>
        </b>{" "}
        <p>
          This is a free information and referral service that opeartes 24-hours
          a day, 7 days a week.
        </p>
        <p>Phone: 2-1-1</p>
        <p>
          <a href="https://www.211ca.org/about-2-1-1" target="_blank">
            2-1-1 coverage areas in California
          </a>
        </p>
        <hr />
      </>
    ),
  },
];
