import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function tomReducer(state = initialState.topicOfMonth, action) {
  switch (action.type) {
    case types.SET_TOPIC_OF_MONTH_SUCCESS:
      return action.topicOfMonth;
    default:
      return state;
  }
}
