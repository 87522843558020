import * as types from "./actionTypes";
import * as tomApi from "../../api/tomApi.js";
import { handleError } from "../../api/apiUtils";
import { beginApiCall } from "./apiStatusActions.js";

export function setTopicOfMonthSuccess(topicOfMonth) {
  return { type: types.SET_TOPIC_OF_MONTH_SUCCESS, topicOfMonth };
}

export function saveTopicOfMonth(token) {
  return async function (dispatch, getState) {
    if (getState().apiCallsInProgress == 0) {
      dispatch(beginApiCall());
      try {
        const topicOfMonth = await tomApi.getTopicOfTheMonth(token);
        dispatch(setTopicOfMonthSuccess(topicOfMonth));
        return topicOfMonth;
      } catch (error) {
        dispatch(handleError(error));
        throw error;
      }
    }
  };
}
