import { handleResponse, handleError } from "./apiUtils";
import axiosInstance from "../api/axios";

/**
 * Retrieve Topic of the month from /library_topic endpoint.
 * @param { string } token
 * @returns {Array} records[]
 */
export function getTopicOfTheMonth(token) {
  return axiosInstance
    .get("/library_topic", {
      headers: { Authorization: `Bearer ${token?.idToken}` },
    })
    .then(handleResponse)
    .then((data) => {
      return buildTopicOfMonth(data);
    })
    .catch(handleError);
}

const buildTopicOfMonth = (data) => {
  const startDate = data.records[0]?.start_date;
  const currentMonth =
    startDate && !isNaN(new Date(startDate))
      ? new Date(startDate).toLocaleString("default", { month: "long" })
      : new Date().toLocaleString("default", { month: "long" });

  return {
    month: currentMonth,
    title: data.records[0]?.name,
    description: data.records[0]?.data.description,
    content: data.records[0]?.data.body,
  };
};
