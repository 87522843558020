import { studentProgramLookup } from "./data";

// Calendly links used for Onboarding and Schedule Kick-off
export const calendlyLinks = [
  {
    id: "data-analysis",
    program: studentProgramLookup.DATA_ANALYSIS,
    onboardingUrl:
      "https://calendly.com/calbright-data-analysis-program/data-analysis-onboarding",
    kickoffUrl:
      "https://calendly.com/calbright-data-analysis-program/data-analysis-kickoff",
  },
  {
    id: "cybersecurity",
    program: studentProgramLookup.CYBERSECURITY,
    onboardingUrl:
      "https://calendly.com/flagship-program-onboarding/cybersecurity-onboarding",
    kickoffUrl:
      "https://calendly.com/calbright-it-pathways/it-support-cybersecurity-program-kickoff",
  },
  {
    id: "crm",
    program: studentProgramLookup.T2T_CRM_ADMIN,
    onboardingUrl:
      "https://calendly.com/crm-platform-administration/onboarding-session",
    kickoffUrl:
      "https://calendly.com/crm-platform-administration/crm-admin-course-kickoff-session",
  },
  {
    id: "it-support",
    program: studentProgramLookup.IT_SUPPORT,
    onboardingUrl:
      "https://calendly.com/flagship-program-onboarding/it-support-onboarding",
    kickoffUrl:
      "https://calendly.com/calbright-it-pathways/it-support-cybersecurity-program-kickoff",
  },
  {
    id: "network-plus",
    program: studentProgramLookup.T2T_INTRO_NETWORKS,
    onboardingUrl:
      "https://calendly.com/calbright-network-plus/network-plus-onboarding-session",
    kickoffUrl:
      "https://calendly.com/calbright-it-pathways/it-support-cybersecurity-program-kickoff",
  },
  {
    id: "medical-coding",
    program: studentProgramLookup.MEDICAL_CODING,
    onboardingUrl:
      "https://calendly.com/flagship-program-onboarding/medical-coding-onboarding",
    kickoffUrl:
      "https://calendly.com/calbright-medical-coding-pathway/medical-coding-program-kickoff",
  },
  {
    id: "project-management",
    program: studentProgramLookup.PROJECT_MANAGEMENT,
    onboardingUrl:
      "https://calendly.com/calbright-project-management/onboarding",
    kickoffUrl:
      "https://calendly.com/pm-instruction/project-management-kickoff",
  },
  {
    id: "hr-learning-development",
    program: studentProgramLookup.HR_LEARNING_DEVELOPMENT,
    onboardingUrl:
      "https://calendly.com/calbright-hr-learning-development/hr-learning-development-onboarding",
    kickoffUrl:
      "https://calendly.com/calbright-hr-learning-development/hr-learning-development-kickoff",
  },
  {
    id: "sandbox-calendly",
    program: "Standbox Environment",
    onboardingUrl:
      "https://calendly.com/calbright-engineering/schedule-onboarding-sandbox",
    kickoffUrl:
      "https://calendly.com/calbright-engineering/schedule-kickoff-sandbox",
  },
];

/** Transitional Calendly links for 2025-01-20 and 2025-2-3,
 * After Feb 3 - Update calendlyLinks with onboardingUrl below
 */
export const calendlyLinksTemp = {
  JANUPDATE: {
    liveDate: "2025-01-20",
    programs: ["Cybersecurity", "T2T Intro to Networks"],
    onboardingUrl:
      "https://calendly.com/flagship-program-onboarding/it-support-onboarding",
  },
  FEBUPDATE: {
    liveDate: "2025-02-03",
    programs: ["Cybersecurity", "T2T Intro to Networks", "IT Support"],
    onboardingUrl:
      "https://calendly.com/calbright-onboarding/calbright-onboarding",
  },
};

/**
 * Check if CRM course is completed
 * @param courses: Array of course objects
 * @param courseCode: e.g. IT520
 */
export const isCRMCourseCompleted = (courses, courseCode) => {
  const CRMCourse = courses.find((course) => course.code === courseCode);

  if (CRMCourse) {
    return CRMCourse.completed || CRMCourse.progress === 100;
  } else {
    return false;
  }
};

/**
 * Determine link to open based on current program and lms
 * @param user: User object
 */
export const handleContinueProgram = (user, action) => {
  const currentProgram = user?.currentProgram;
  const courses = user?.courses;
  const programVersion = user?.programVersion;
  const lms = user?.lms;
  const CRMProgram = studentProgramLookup["T2T_CRM_ADMIN"];

  if (currentProgram === CRMProgram) {
    const courseBaseLinks = {
      IT520: "https://calbright.cc/trailhead-IT520",
      IT525: "https://calbright.cc/trailhead-IT525",
    };
    const courseVersions = {
      IT520: "v3",
      IT525: "v2",
    };
    let defaultLink = courseBaseLinks["IT520"];
    let defaultVersion = courseVersions["IT520"];

    if (isCRMCourseCompleted(courses, "IT520")) {
      defaultLink = courseBaseLinks["IT525"];
      defaultVersion = courseVersions["IT525"];
    }

    if (programVersion?.includes("IT525 - v2")) {
      defaultLink = defaultLink + "-" + defaultVersion;
    }
    window.open(defaultLink, "_blank");
  } else {
    if (lms === "Canvas") {
      window.open(process.env.CANVAS_URL, "_blank");
    } else if (lms === "Strut") {
      window.open("https://www.calbright.edu/strut", "_blank");
    } else if (lms === "myTrailhead") {
      window.open("https://calbright.cc/trailhead-IT520", "_blank");
    }
  }
};

/**
 * Returns link based on course passed
 * @param courseCode: e.g. IT520
 * @param programVersion: e.g. v3
 */
export const handleCourseLink = (courseCode, programVersion) => {
  if (courseCode.includes("IT520") || courseCode.includes("IT525")) {
    const courseBaseLinks = {
      IT520: "https://calbright.cc/trailhead-IT520",
      IT525: "https://calbright.cc/trailhead-IT525",
    };
    const courseVersions = {
      IT520: "v3",
      IT525: "v2",
    };

    const courseVersion = programVersion?.includes("IT525 - v2")
      ? "-" + courseVersions[courseCode]
      : "";

    return courseBaseLinks[courseCode] + courseVersion;
  } else {
    return "";
  }
};

/**
 * Adds the current user cccID to firstSAAViewed (array) in localStorage if not already set.
 * @param {*} cccID
 * @returns null || Array of cccIDs
 */
export const setFirstSAAData = (cccID) => {
  if (!cccID) return null;

  const firstSAASaved =
    JSON.parse(localStorage.getItem("firstSAAViewedList")) || [];

  if (!firstSAASaved.includes(cccID)) {
    firstSAASaved.push(cccID);
    localStorage.setItem("firstSAAViewedList", JSON.stringify(firstSAASaved));
  }
  return firstSAASaved;
};
