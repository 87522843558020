import { combineReducers } from "redux";
import apiCallsInProgress from "./apiStatusReducer";
import user from "./userReducer";
import token from "./tokenReducer";
import signOut from "./signOutReducer";
import { buttonChatReducer as buttonChatDisabled } from "./helpReducer";
import appointment, {
  appointmentLinksReducer as appointmentLinks,
} from "./appointmentReducer";
import teamData from "./myteamReducer";
import preReqStep from "./preReqStepReducer";
import avatar, { avatarsReducer as avatars } from "./avatarReducer";
import loading from "./loadingReducer";
import broadcastMsgs from "./broadcastMsgReducer";
import topicOfMonth from "./tomReducer";

const rootReducer = combineReducers({
  apiCallsInProgress,
  loading,
  token,
  user,
  broadcastMsgs,
  appointment,
  appointmentLinks,
  signOut,
  teamData,
  preReqStep,
  avatars,
  avatar,
  buttonChatDisabled,
  topicOfMonth,
});

export default rootReducer;
